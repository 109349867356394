
import { Options, Vue } from 'vue-class-component';
import ResultsTable from '@/components/ResultsTable.vue';

@Options({
  components: { ResultsTable },
  props: {
    year: String,
  },
})
export default class YearResults extends Vue {
  year!: string;
}
