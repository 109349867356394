import ICompetitor from '@/models/interfaces/ICompetitor';

export default class Competitor implements ICompetitor {
  name: string;

  surname: string;

  notes: string;

  novice: boolean;

  active: boolean;

  id: string;

  constructor(name: string, surname: string, id: string, novice: boolean, active: boolean, notes: string) {
    this.name = name;
    this.surname = surname;
    this.novice = novice;
    this.active = active;
    this.notes = notes;
    this.id = id;
  }
}
