<template>
  <div
    class="bg-white overflow-hidden shadow rounded-lg divide-y divide-gray-200 my-5"
    v-if="noviceBestInShow.id || bestInShow.id"
  >
    <div class="px-4 py-5 sm:p-6">
      <dl class="mt-5 grid grid-cols-1 gap-5">
        <best-in-show :person="bestInShow" />
        <best-in-show novice :person="noviceBestInShow"/>
      </dl>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import Placing from '@/components/Placing.vue';
import BestInShow from '@/components/BestInShow.vue';
import Competitor from '@/models/Competitor';

@Options({
  components: { BestInShow, Placing },
  props: {
    bestInShow: Competitor,
    noviceBestInShow: Competitor,
  },
})
export default class ShowJudgeBestInShow extends Vue {
}
</script>
