
import { Options, Vue } from 'vue-class-component';
import Placing from '@/components/Placing.vue';
import BestInShow from '@/components/BestInShow.vue';
import Competitor from '@/models/Competitor';

@Options({
  components: { BestInShow, Placing },
  props: {
    bestInShow: Competitor,
    noviceBestInShow: Competitor,
  },
})
export default class ShowJudgeBestInShow extends Vue {
}
