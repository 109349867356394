<template>
  <div v-if="currentShow">
    <!-- Page header -->
    <div class="bg-white shadow sticky">
      <div class="px-4 sm:px-6 lg:max-w-6xl lg:mx-auto lg:px-8">
        <div class="py-6 md:flex md:items-center md:justify-between lg:border-t lg:border-gray-200">
          <div class="flex-1 min-w-0">
            <div class="flex items-center">
              <h2 class="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
                {{ currentShow.name }} - {{ year }}
              </h2>
            </div>
            <div class="mt-1 flex flex-col lg:flex-row lg:flex-wrap sm:mt-0">
              <div class="mt-2 flex items-center text-sm text-gray-500 lg:mr-2">
                <CalendarIcon class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                {{ showDate }}
              </div>
              <div class="mt-2 flex items-center text-sm text-gray-500 lg:mr-2">
                <LocationMarkerIcon class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                {{ currentShow.location }}
              </div>
              <div v-if="currentShow.judge" class="mt-2 flex items-center text-sm text-gray-500 lg:mr-2">
                <ScaleIcon class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                Judged by - {{ currentShow.judge }}
              </div>
            </div>
          </div>
          <div class="mt-6 flex space-x-3 md:mt-0 md:ml-4">
            <router-link
              type="button"
              class="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              :to="{name: 'YearDetail', year: year }"
            >
              Back
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <show-category-list
      :categories="currentShow.categories"
      :best-in-show="currentShow.bestInShow"
      :novice-best-in-show="currentShow.noviceBestInShow"
      :slug="currentShow.slug"
      :year="year"/>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import {
  Dialog, DialogOverlay, TransitionChild, TransitionRoot,
} from '@headlessui/vue';
import {
  MenuIcon,
  XIcon,
  LocationMarkerIcon,
  PencilIcon,
  CalendarIcon,
  ScaleIcon,
} from '@heroicons/vue/outline';
import { ChevronLeftIcon } from '@heroicons/vue/solid';
import YearShowList from '@/components/YearShowList.vue';
import AddEditCategorySlideOver from '@/components/SlideOvers/AddEditCategorySlideOver.vue';
import IShow from '@/models/interfaces/IShow';
import ShowCategoryList from '@/components/ShowCategoryList.vue';
import EditShowModal from '@/components/modals/EditShowModal.vue';
import ICategory from '@/models/interfaces/ICategory';
import Category from '@/models/Category';

@Options({
  components: {
    EditShowModal,
    ShowCategoryList,
    AddEditCategorySlideOver,
    YearShowList,
    Dialog,
    DialogOverlay,
    TransitionChild,
    TransitionRoot,
    ChevronLeftIcon,
    MenuIcon,
    XIcon,
    LocationMarkerIcon,
    PencilIcon,
    CalendarIcon,
    ScaleIcon,
  },
})
export default class Show extends Vue {
  currentShow: IShow | null = null;

  created(): void {
    this.loadShows();
  }

  get year(): string {
    return Array.isArray(this.$route.params.year) ? this.$route.params.year[0] : this.$route.params.year;
  }

  get slug(): string {
    return Array.isArray(this.$route.params.slug) ? this.$route.params.slug[0] : this.$route.params.slug;
  }

  get showDate(): string {
    const options = {
      weekday: 'long', year: 'numeric', month: 'long', day: 'numeric',
    };
    return this.currentShow ? new Date(this.currentShow.date).toLocaleDateString('en-GB', options) : '';
  }

  // eslint-disable-next-line class-methods-use-this
  loadShows(): void {
    console.log('Loading shows...');
    this.axios.get(`${process.env.VUE_APP_APIURL}/years/${this.year}/shows/${this.slug}`)
      .then((res) => {
        console.log(res);
        this.currentShow = res.data;
      });
  }
}
</script>
