<template>
  <div class="relative bg-white pt-5 px-4 pb-12 sm:pt-6 sm:px-6 shadow rounded-lg overflow-hidden">
    <dt>
      <div class="absolute bg-indigo-500 rounded-md p-3 text-white">
        {{ positionText }}
      </div>
    </dt>
    <dd class="ml-16 pb-6 flex items-baseline sm:pb-7">
      <p class="text-2xl font-semibold text-gray-900">
        {{ person?.id ? `${person.name} ${person.surname}` : 'No Entry'}}
      </p>
    </dd>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import Competitor from '@/models/Competitor';

@Options({
  props: {
    position: Number,
    person: Competitor,
    slug: String,
    year: String,
    categoryId: Number,
  },
  emits: ['update'],
})
export default class Placing extends Vue {
  position!: 1|2|3

  get positionText(): string {
    switch (this.position) {
      case 1: return '1st';
      case 2: return '2nd';
      case 3: return '3rd';
      default: return '';
    }
  }
}
</script>
