<template>
  <div class="relative flex bg-white pt-5 px-4 pb-12 sm:pt-6 sm:px-6 shadow rounded-lg overflow-hidden" v-if="person.id">
    <dt>
      <div class="bg-indigo-500 rounded-md p-3 text-white">
        {{ novice ? 'Novice ' : '' }}Best In Show
      </div>
    </dt>
    <dd class="ml-16 pb-6 flex items-baseline sm:pb-7">
      <p class="text-2xl font-semibold text-gray-900">
        {{ person?.id ? `${person.name} ${person.surname}` : 'Not Set' }}
      </p>
    </dd>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import Competitor from '@/models/Competitor';

@Options({
  props: {
    person: Competitor,
    novice: {
      type: Boolean,
      default: false,
    },
  },
})
export default class BestInShow extends Vue {
}
</script>
