
import { Options, Vue } from 'vue-class-component';
import YearResults from '@/components/YearResults.vue';
import SelectMenu from '@/components/SelectMenu.vue';

@Options({
  components: {
    SelectMenu,
    YearResults,
  },
})
export default class Year extends Vue {
  years: { name: string, id: string }[] = [];

  created() {
    this.loadResults();
  }

  get year(): string {
    return Array.isArray(this.$route.params.year) ? this.$route.params.year[0] : this.$route.params.year;
  }

  get listYear(): { name: string, id: string} {
    return this.years.find((y) => y.name === this.year) ?? { name: '', id: '' };
  }

  set listYear(val: { name: string, id: string }) {
    console.log('here');
    this.$router.push({ name: 'Year', params: { year: val.name } });
  }

  // eslint-disable-next-line class-methods-use-this
  loadResults(): void {
    console.log('Loading results...');
    this.axios.get(`${process.env.VUE_APP_APIURL}/totals`)
      .then((res) => {
        const years = res.data.map((r: {name: string}) => ({ name: r.name, id: r.name }));
        console.log(years);
        this.years = years;
      });
  }
}
