
import { Options, Vue } from 'vue-class-component';
import {
  Dialog, DialogOverlay, TransitionChild, TransitionRoot,
} from '@headlessui/vue';
import {
  MenuIcon,
  XIcon,
  LocationMarkerIcon,
  PencilIcon,
  CalendarIcon,
  ScaleIcon,
} from '@heroicons/vue/outline';
import { ChevronLeftIcon } from '@heroicons/vue/solid';
import YearShowList from '@/components/YearShowList.vue';
import AddEditCategorySlideOver from '@/components/SlideOvers/AddEditCategorySlideOver.vue';
import IShow from '@/models/interfaces/IShow';
import ShowCategoryList from '@/components/ShowCategoryList.vue';
import EditShowModal from '@/components/modals/EditShowModal.vue';
import ICategory from '@/models/interfaces/ICategory';
import Category from '@/models/Category';

@Options({
  components: {
    EditShowModal,
    ShowCategoryList,
    AddEditCategorySlideOver,
    YearShowList,
    Dialog,
    DialogOverlay,
    TransitionChild,
    TransitionRoot,
    ChevronLeftIcon,
    MenuIcon,
    XIcon,
    LocationMarkerIcon,
    PencilIcon,
    CalendarIcon,
    ScaleIcon,
  },
})
export default class Show extends Vue {
  currentShow: IShow | null = null;

  created(): void {
    this.loadShows();
  }

  get year(): string {
    return Array.isArray(this.$route.params.year) ? this.$route.params.year[0] : this.$route.params.year;
  }

  get slug(): string {
    return Array.isArray(this.$route.params.slug) ? this.$route.params.slug[0] : this.$route.params.slug;
  }

  get showDate(): string {
    const options = {
      weekday: 'long', year: 'numeric', month: 'long', day: 'numeric',
    };
    return this.currentShow ? new Date(this.currentShow.date).toLocaleDateString('en-GB', options) : '';
  }

  // eslint-disable-next-line class-methods-use-this
  loadShows(): void {
    console.log('Loading shows...');
    this.axios.get(`${process.env.VUE_APP_APIURL}/years/${this.year}/shows/${this.slug}`)
      .then((res) => {
        console.log(res);
        this.currentShow = res.data;
      });
  }
}
