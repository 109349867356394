
import { Options, Vue } from 'vue-class-component';
import {
  Dialog,
  DialogOverlay,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  TransitionChild,
  TransitionRoot,
} from '@headlessui/vue';
import {
  BellIcon,
  ClockIcon,
  CogIcon,
  CreditCardIcon,
  DocumentReportIcon,
  HomeIcon,
  MenuAlt1Icon,
  QuestionMarkCircleIcon,
  ScaleIcon,
  ShieldCheckIcon,
  UserGroupIcon,
  XIcon,
} from '@heroicons/vue/outline';
import {
  CashIcon,
  CheckCircleIcon,
  ChevronDownIcon,
  ChevronRightIcon,
  OfficeBuildingIcon,
  SearchIcon,
} from '@heroicons/vue/solid';

@Options({
  components: {
    Dialog,
    DialogOverlay,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    TransitionChild,
    TransitionRoot,
    BellIcon,
    CashIcon,
    CheckCircleIcon,
    ChevronDownIcon,
    ChevronRightIcon,
    MenuAlt1Icon,
    OfficeBuildingIcon,
    SearchIcon,
    XIcon,
  },
})
export default class Home extends Vue {
  cards = [
  ]

  mounted(): void {
    this.refreshData();
  }

  private refreshData() {
    this.axios.get(`${process.env.VUE_APP_APIURL}/totals`)
      // eslint-disable-next-line no-return-assign
      .then((res) => this.cards = res.data.map((d: { id: number, name: string }) => ({
        name: d.name,
        href: '#',
      })));
  }

  // eslint-disable-next-line class-methods-use-this
  get salutation(): string {
    const today = new Date();
    const curHr = today.getHours();

    if (curHr < 12) {
      return 'Good morning';
    } if (curHr < 18) {
      return 'Good afternoon';
    }
    return 'Good evening';
  }

  sidebarOpen = false;
}
