
import { Options, Vue } from 'vue-class-component';
import {
  Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot, Switch, SwitchDescription, SwitchGroup, SwitchLabel,
} from '@headlessui/vue';
import { CheckIcon } from '@heroicons/vue/outline';
import SelectMenu from '@/components/SelectMenu.vue';
import Months from '@/data/months';

@Options({
  components: {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    CheckIcon,
    SelectMenu,
    Switch,
    SwitchDescription,
    SwitchGroup,
    SwitchLabel,
  },
  props: {
    open: Boolean,
    editingItem: {
      id: String,
      name: String,
      published: Boolean,
    },
  },
  emits: [
    'closed',
  ],
})
export default class EditYearModal extends Vue {
  editingItem!: { id: string, name: string, published: boolean };

  slug!: string;

  year!: string;

  location = '';

  // eslint-disable-next-line class-methods-use-this
  get months(): { id: number, name: string }[] {
    return Months;
  }

  get name(): string {
    return this.editingItem?.name;
  }

  set name(val: string) {
    this.editingItem.name = val;
  }

  get isPublished(): boolean {
    return this.editingItem?.published;
  }

  set isPublished(val: boolean) {
    this.editingItem.published = val;
  }

  created() {
    console.log(this.editingItem);
  }

  close() {
    this.$emit('closed');
  }

  update() {
    // Send request to update show
    this.$http.post(
      `${process.env.VUE_APP_APIURL}/years`, this.editingItem,
    ).then((res) => {
      // Then close & reload
      this.$emit('closed', true);
    });
  }
}
