
import { Options, Vue } from 'vue-class-component';
import {
  Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot,
} from '@headlessui/vue';
import { XIcon } from '@heroicons/vue/outline';
import { LinkIcon, PlusIcon, QuestionMarkCircleIcon } from '@heroicons/vue/solid';
import SelectMenu from '@/components/SelectMenu.vue';
import Months from '@/data/months';

@Options({
  components: {
    SelectMenu,
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    LinkIcon,
    PlusIcon,
    QuestionMarkCircleIcon,
    XIcon,
  },
  props: {
    open: Boolean,
    editingItem: {
      name: String, year: String, location: String, date: Date,
    },
  },
  emits: [
    'closed',
  ],
})
export default class AddEditShowSlideOver extends Vue {
  open!: boolean;

  editingItem!: { name: string, year: string, location: string, date: Date };

  selectedMonthValue: { id: number, name: string } = Months[0];

  selectedDateValue = 1;

  selectedYearValue = 2000;

  selectedName = '';

  close(refresh = false): void {
    this.$emit('closed', refresh);
  }

  create(): void {
    this.axios.put(`${process.env.VUE_APP_APIURL}/years/${this.editingItem.year}/shows`, this.editingItem)
      .then(() => this.close(true));
  }

  // eslint-disable-next-line class-methods-use-this
  get months(): { id: number, name: string }[] {
    return Months;
  }

  get selectedDate() {
    return this.editingItem.date.getDate();
  }

  set selectedDate(val: number) {
    this.selectedDateValue = val;
    this.editingItem.date = new Date(this.selectedYear, this.selectedMonth!.id - 1, val);
  }

  get selectedMonth(): { id: number, name: string } {
    return this.months[this.editingItem.date.getMonth()];
  }

  set selectedMonth(val: { id: number, name: string }) {
    this.selectedMonthValue = val;
    this.editingItem.date = new Date(this.selectedYear, val!.id - 1, this.selectedDate);
  }

  get selectedYear(): number {
    return this.editingItem.date.getFullYear();
  }

  set selectedYear(val: number) {
    this.selectedYearValue = val;
    this.editingItem.date = new Date(val, this.selectedMonth!.id - 1, this.selectedDate);
  }
}
