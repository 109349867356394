<template>
  <div>
    <h3 class="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:tracking-tight">Open Classes</h3>
    <ResultsTable
      :year="year"
    />
    <br/>
    <h3 class="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:tracking-tight">Novice Classes</h3>
    <ResultsTable
      novice
      :year="year"
    />
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import ResultsTable from '@/components/ResultsTable.vue';

@Options({
  components: { ResultsTable },
  props: {
    year: String,
  },
})
export default class YearResults extends Vue {
  year!: string;
}
</script>
