<template>
  <div class="mx-auto max-w-7xl sm:px-6 lg:px-8 py-4">
    <div class="md:flex md:items-center md:justify-between">
      <div class="min-w-0 flex-1">
        <h2 class="text-4xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">{{ year }}</h2>
      </div>
      <div class="mt-4 flex md:mt-0 md:ml-4 flex-1">
        <select-menu class="inline-flex flex-col w-1/2" :items="years" v-model="listYear" label="Show results for:"/>
      </div>
    </div>
    <!-- Content goes here -->
    <YearResults
      class="mt-4"
      :year="year"
    />
    <router-link
      type="button"
      :to="{ name: 'YearDetail', year: year }"

      class="mt-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
      View Details
    </router-link>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import YearResults from '@/components/YearResults.vue';
import SelectMenu from '@/components/SelectMenu.vue';

@Options({
  components: {
    SelectMenu,
    YearResults,
  },
})
export default class Year extends Vue {
  years: { name: string, id: string }[] = [];

  created() {
    this.loadResults();
  }

  get year(): string {
    return Array.isArray(this.$route.params.year) ? this.$route.params.year[0] : this.$route.params.year;
  }

  get listYear(): { name: string, id: string} {
    return this.years.find((y) => y.name === this.year) ?? { name: '', id: '' };
  }

  set listYear(val: { name: string, id: string }) {
    console.log('here');
    this.$router.push({ name: 'Year', params: { year: val.name } });
  }

  // eslint-disable-next-line class-methods-use-this
  loadResults(): void {
    console.log('Loading results...');
    this.axios.get(`${process.env.VUE_APP_APIURL}/totals`)
      .then((res) => {
        const years = res.data.map((r: {name: string}) => ({ name: r.name, id: r.name }));
        console.log(years);
        this.years = years;
      });
  }
}
</script>
