<template>
  <div class="sm:px-5">
    <div
      class="bg-white overflow-hidden shadow rounded-lg divide-y divide-gray-200 my-5"
      v-for="(item, idx) in categories"
      :key="idx"
    >

      <div class="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
        <div class="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap">
          <div class="ml-4 mt-2">
            <h3 class="text-lg leading-6 font-medium text-gray-900">
              {{item.name}} - {{ item.description }}
              <span v-if="item.novice" class="inline-flex items-center px-3 py-0.5 my-1 rounded-full text-sm font-medium bg-indigo-100 text-indigo-800">
                Novice
              </span>
            </h3>
          </div>
        </div>
      </div>
      <div class="px-4 py-5 sm:p-6">
        <dl class="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
          <placing :position="1" @update="update" :person="item.placings.first" v-bind="$props" :categoryId="item.id"/>
          <placing :position="2" @update="update" :person="item.placings.second" v-bind="$props" :categoryId="item.id"/>
          <placing :position="3" @update="update" :person="item.placings.third" v-bind="$props" :categoryId="item.id"/>
        </dl>
      </div>
    </div>
    <show-judge-best-in-show
      :best-in-show="bestInShow"
      :novice-best-in-show="noviceBestInShow"/>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import Category from '@/models/Category';
import Placing from '@/components/Placing.vue';
import ShowJudgeBestInShow from '@/components/ShowJudgeBestInShow.vue';
import Competitor from '@/models/Competitor';

@Options({
  components: { ShowJudgeBestInShow, Placing },
  props: {
    categories: Array(Category),
    bestInShow: Competitor,
    noviceBestInShow: Competitor,
  },
})
export default class ShowCategoryList extends Vue {
}
</script>
