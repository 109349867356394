
import { Options, Vue } from 'vue-class-component';
import { CheckCircleIcon, ChevronRightIcon } from '@heroicons/vue/solid';

@Options({
  components: {
    CheckCircleIcon,
    ChevronRightIcon,
  },
  props: {
    year: String,
    novice: {
      type: Boolean,
      default: false,
    },
  },
})
export default class ResultsTable extends Vue {
  year!: string;

  novice!: boolean;

  competitors = [];

  created() {
    console.log('Loading results...');
    this.axios.get(`${process.env.VUE_APP_APIURL}/totals/${this.year}?top=10${this.novice ? '&novice=true' : ''}`)
      .then((res) => {
        console.log(res.data.competitors);
        this.competitors = res.data.competitors;
      });
  }

  // eslint-disable-next-line class-methods-use-this
  getPosition(position: number): string {
    return `${position}${this.suffixes[position] ?? ''}`;
  }

  suffixes: { [name: string]: string } = {
    1: 'st',
    2: 'nd',
    3: 'rd',
    4: 'th',
    5: 'th',
    6: 'th',
    7: 'th',
    8: 'th',
    9: 'th',
    10: 'th',
    11: 'th',
    12: 'th',
    13: 'th',
    14: 'th',
    15: 'th',
    16: 'th',
    17: 'th',
    18: 'th',
    19: 'th',
    20: 'th',
    21: 'st',
    22: 'nd',
    23: 'rd',
    24: 'th',
    25: 'th',
    26: 'th',
    27: 'th',
    28: 'th',
    29: 'th',
    30: 'th',
    31: 'st',
  }
}
