<template>
  <div class="bg-white shadow overflow-hidden sm:rounded-md">
    <ul role="list" class="divide-y divide-gray-200">
      <li v-for="(competitor, idx) in competitors" :key="idx">
        <a href="#" class="block hover:bg-gray-50">
          <div class="flex items-center px-4 py-4 sm:px-6">
            <div class="min-w-0 flex-1 flex items-center">
              <div class="flex-shrink-0">
<!--                <img class="h-12 w-12 rounded-full" :src="application.applicant.imageUrl" alt="" />-->
              </div>
              <div class="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                <div>
                  <p class="text-sm font-medium text-indigo-600 truncate">{{ competitor.name }} {{ competitor.surname }}</p>
                  <p class="mt-2 flex items-center text-sm text-gray-500">
                    <span class="truncate">{{ getPosition(competitor.position) }} place</span>
                  </p>
                </div>
                <div class="hidden md:block">
                  <div>
                    <p class="text-sm text-gray-900">
                      {{ competitor.score }} points
                    </p>
                    <p class="mt-2 flex items-center text-sm text-gray-500">
                      {{ competitor.numberOfShows }} shows
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <ChevronRightIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
            </div>
          </div>
        </a>
      </li>
    </ul>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { CheckCircleIcon, ChevronRightIcon } from '@heroicons/vue/solid';

@Options({
  components: {
    CheckCircleIcon,
    ChevronRightIcon,
  },
  props: {
    year: String,
    novice: {
      type: Boolean,
      default: false,
    },
  },
})
export default class ResultsTable extends Vue {
  year!: string;

  novice!: boolean;

  competitors = [];

  created() {
    console.log('Loading results...');
    this.axios.get(`${process.env.VUE_APP_APIURL}/totals/${this.year}?top=10${this.novice ? '&novice=true' : ''}`)
      .then((res) => {
        console.log(res.data.competitors);
        this.competitors = res.data.competitors;
      });
  }

  // eslint-disable-next-line class-methods-use-this
  getPosition(position: number): string {
    return `${position}${this.suffixes[position] ?? ''}`;
  }

  suffixes: { [name: string]: string } = {
    1: 'st',
    2: 'nd',
    3: 'rd',
    4: 'th',
    5: 'th',
    6: 'th',
    7: 'th',
    8: 'th',
    9: 'th',
    10: 'th',
    11: 'th',
    12: 'th',
    13: 'th',
    14: 'th',
    15: 'th',
    16: 'th',
    17: 'th',
    18: 'th',
    19: 'th',
    20: 'th',
    21: 'st',
    22: 'nd',
    23: 'rd',
    24: 'th',
    25: 'th',
    26: 'th',
    27: 'th',
    28: 'th',
    29: 'th',
    30: 'th',
    31: 'st',
  }
}
</script>
