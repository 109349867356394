import { createStore } from 'vuex';

export default createStore({
  state: {
    editingCategory: {
      name: '',
      description: '',
      novice: false,
    },
    editingCompetitor: {
      name: '',
      surname: '',
      notes: '',
      id: null,
      novice: false,
      active: true,
    },
  },
  mutations: {
    editCategory(state, payload) {
      state.editingCategory = payload;
    },
    addCategory(state, payload) {
      state.editingCategory = payload;
    },
    setEditCategoryName(state, payload) {
      state.editingCategory.name = payload;
    },
    setEditCategoryDescription(state, payload) {
      state.editingCategory.description = payload;
    },
    setEditCategoryNovice(state, payload) {
      state.editingCategory.novice = payload;
    },
    editCompetitor(state, payload) {
      state.editingCompetitor = payload;
    },
    addCompetitor(state, payload) {
      state.editingCompetitor = payload;
    },
    setEditCompetitorName(state, payload) {
      state.editingCompetitor.name = payload;
    },
    setEditCompetitorSurname(state, payload) {
      state.editingCompetitor.surname = payload;
    },
    setEditCompetitorNotes(state, payload) {
      state.editingCompetitor.notes = payload;
    },
    setEditCompetitorNovice(state, payload) {
      state.editingCompetitor.novice = payload;
    },
    setEditCompetitorActive(state, payload) {
      state.editingCompetitor.active = payload;
    },
  },
  actions: {
    addCategory(context) {
      context.commit('addCategory', { name: '', description: '', id: undefined });
    },
    editCategory(context, payload) {
      context.commit('editCategory', payload);
    },
    setEditCategoryName(context, payload) {
      context.commit('setEditCategoryName', payload);
    },
    setEditCategoryDescription(context, payload) {
      context.commit('setEditCategoryDescription', payload);
    },
    setEditCategoryNovice(context, payload) {
      context.commit('setEditCategoryNovice', payload);
    },
    addCompetitor(context) {
      context.commit('addCompetitor', {
        name: '',
        surname: '',
        notes: '',
        novice: false,
        active: true,
        id: null,
      });
    },
    editCompetitor(context, payload) {
      context.commit('editCompetitor', payload);
    },
    setEditCompetitorName(context, payload) {
      context.commit('setEditCompetitorName', payload);
    },
    setEditCompetitorSurname(context, payload) {
      context.commit('setEditCompetitorSurname', payload);
    },
    setEditCompetitorNotes(context, payload) {
      context.commit('setEditCompetitorNotes', payload);
    },
    setEditCompetitorNovice(context, payload) {
      context.commit('setEditCompetitorNovice', payload);
    },
    setEditCompetitorActive(context, payload) {
      context.commit('setEditCompetitorActive', payload);
    },
  },
  modules: {
  },
});
