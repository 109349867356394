
import { Options, Vue } from 'vue-class-component';
import Competitor from '@/models/Competitor';

@Options({
  props: {
    person: Competitor,
    novice: {
      type: Boolean,
      default: false,
    },
  },
})
export default class BestInShow extends Vue {
}
