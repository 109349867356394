
import { Options, Vue } from 'vue-class-component';
import Category from '@/models/Category';
import Placing from '@/components/Placing.vue';
import ShowJudgeBestInShow from '@/components/ShowJudgeBestInShow.vue';
import Competitor from '@/models/Competitor';

@Options({
  components: { ShowJudgeBestInShow, Placing },
  props: {
    categories: Array(Category),
    bestInShow: Competitor,
    noviceBestInShow: Competitor,
  },
})
export default class ShowCategoryList extends Vue {
}
