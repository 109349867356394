<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" static class="z-10 fixed inset-0 overflow-hidden" @close="close" :open="open">
      <div class="absolute inset-0 overflow-hidden">
        <DialogOverlay class="absolute inset-0" />

        <div class="fixed inset-y-0 right-0 pl-10 max-w-full flex sm:pl-16">
          <TransitionChild as="template" enter="transform transition ease-in-out duration-500 sm:duration-700" enter-from="translate-x-full" enter-to="translate-x-0" leave="transform transition ease-in-out duration-500 sm:duration-700" leave-from="translate-x-0" leave-to="translate-x-full">
            <div class="w-screen max-w-2xl">
              <form class="h-full flex flex-col bg-white shadow-xl overflow-y-scroll">
                <div class="flex-1">
                  <!-- Header -->
                  <div class="px-4 py-6 bg-gray-50 sm:px-6">
                    <div class="flex items-start justify-between space-x-3">
                      <div class="space-y-1">
                        <DialogTitle class="text-lg font-medium text-gray-900">
                          Create new show
                        </DialogTitle>
                        <p class="text-sm text-gray-500">
                          Creates a new show
                        </p>
                      </div>
                      <div class="h-7 flex items-center">
                        <button type="button" class="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                                @click="close(false)">
                          <span class="sr-only">Close panel</span>
                          <XIcon class="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                  </div>

                  <!-- Divider container -->
                  <div class="py-6 space-y-6 sm:py-0 sm:space-y-0 sm:divide-y sm:divide-gray-200">
                    <div class="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                      <div>
                        <label for="name" class="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2">
                          Name
                        </label>
                      </div>
                      <div class="sm:col-span-2">
                        <input type="text" name="name" id="name" class="block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md" v-model="editingItem.name" />
                      </div>

                      <div>
                        <label for="location" class="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2">
                          Location
                        </label>
                      </div>
                      <div class="sm:col-span-2">
                        <input type="text" name="location" id="location" class="block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md" v-model="editingItem.location" />
                      </div>

                      <div>
                        <label for="month" class="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2">
                          Month
                        </label>
                      </div>
                      <div class="sm:col-span-2">
                        <select-menu :items="months" v-model="selectedMonth"/>
                        <!--                        <input type="text" name="year" id="year" class="block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md" v-model="editingItem.name" />-->
                      </div>
                      <div>
                        <label for="date" class="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2">
                          Date
                        </label>
                      </div>
                      <div class="sm:col-span-2">
                        <input type="number" name="date" id="date" class="block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md" v-model="selectedDate" />
                      </div>
                      <div>
                        <label for="year" class="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2">
                          Year
                        </label>
                      </div>
                      <div class="sm:col-span-2">
                        <input type="number" name="year" id="year" class="block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md" v-model="selectedYear" />
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Action buttons -->
                <div class="flex-shrink-0 px-4 border-t border-gray-200 py-5 sm:px-6">
                  <div class="space-x-3 flex justify-end">
                    <button type="button" class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            @click="close(false)">
                      Cancel
                    </button>
                    <button type="button" @click="create" class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                      Create
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import {
  Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot,
} from '@headlessui/vue';
import { XIcon } from '@heroicons/vue/outline';
import { LinkIcon, PlusIcon, QuestionMarkCircleIcon } from '@heroicons/vue/solid';
import SelectMenu from '@/components/SelectMenu.vue';
import Months from '@/data/months';

@Options({
  components: {
    SelectMenu,
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    LinkIcon,
    PlusIcon,
    QuestionMarkCircleIcon,
    XIcon,
  },
  props: {
    open: Boolean,
    editingItem: {
      name: String, year: String, location: String, date: Date,
    },
  },
  emits: [
    'closed',
  ],
})
export default class AddEditShowSlideOver extends Vue {
  open!: boolean;

  editingItem!: { name: string, year: string, location: string, date: Date };

  selectedMonthValue: { id: number, name: string } = Months[0];

  selectedDateValue = 1;

  selectedYearValue = 2000;

  selectedName = '';

  close(refresh = false): void {
    this.$emit('closed', refresh);
  }

  create(): void {
    this.axios.put(`${process.env.VUE_APP_APIURL}/years/${this.editingItem.year}/shows`, this.editingItem)
      .then(() => this.close(true));
  }

  // eslint-disable-next-line class-methods-use-this
  get months(): { id: number, name: string }[] {
    return Months;
  }

  get selectedDate() {
    return this.editingItem.date.getDate();
  }

  set selectedDate(val: number) {
    this.selectedDateValue = val;
    this.editingItem.date = new Date(this.selectedYear, this.selectedMonth!.id - 1, val);
  }

  get selectedMonth(): { id: number, name: string } {
    return this.months[this.editingItem.date.getMonth()];
  }

  set selectedMonth(val: { id: number, name: string }) {
    this.selectedMonthValue = val;
    this.editingItem.date = new Date(this.selectedYear, val!.id - 1, this.selectedDate);
  }

  get selectedYear(): number {
    return this.editingItem.date.getFullYear();
  }

  set selectedYear(val: number) {
    this.selectedYearValue = val;
    this.editingItem.date = new Date(val, this.selectedMonth!.id - 1, this.selectedDate);
  }
}
</script>
