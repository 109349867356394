
import { Options, Vue } from 'vue-class-component';
import IShow from '@/models/interfaces/IShow';

function dateRange(startDate: Date, endDate: Date): Date[] {
  const start = new Date(startDate);
  const startYear = start.getFullYear();
  const end = new Date(endDate);
  const endYear = end.getFullYear();
  const dates = [];

  // eslint-disable-next-line no-plusplus
  for (let i = startYear; i <= endYear; i++) {
    const endMonth = i !== endYear ? 11 : end.getMonth();
    const startMon = i !== startYear ? 0 : start.getMonth();
    for (let j = startMon; j <= endMonth; j = j > 12 ? j % 12 || 11 : j + 1) {
      dates.push(new Date(i, j, 1));
    }
  }
  return dates;
}

@Options({
  props: {
    shows: Array,
    year: String,
  },
})
export default class YearShowList extends Vue {
  year!: string;

  // eslint-disable-next-line class-methods-use-this
  get calendar(): { name: string, shows: IShow[] }[] | null {
    if (!this.shows) return null;
    this.shows.sort((a, b) => (a.date > b.date ? 1 : -1));
    const firstShow = this.shows[0].date;
    const lastShow = this.shows[this.shows.length - 1].date;
    return dateRange(firstShow, lastShow).map((month: Date) => ({
      name: month.toLocaleString('default', { month: 'long', year: 'numeric' }),
      shows: this.shows.filter((show) => new Date(show.date).getMonth() === month.getMonth() && new Date(show.date).getFullYear() === month.getFullYear()).sort((a, b) => new Date(a.date).valueOf() - new Date(b.date).valueOf()),
    }));
  }

  shows!: IShow[];

  // eslint-disable-next-line class-methods-use-this
  getShowDate(date: Date): string {
    const day = new Date(date).getDate().toString();
    return `${day}${this.suffixes[day]}`;
  }

  viewShow(show: IShow) {
    this.$router.push({ name: 'Show', params: { year: this.year, slug: show.slug } });
  }

  suffixes: { [name: string]: string } = {
    1: 'st',
    2: 'nd',
    3: 'rd',
    4: 'th',
    5: 'th',
    6: 'th',
    7: 'th',
    8: 'th',
    9: 'th',
    10: 'th',
    11: 'th',
    12: 'th',
    13: 'th',
    14: 'th',
    15: 'th',
    16: 'th',
    17: 'th',
    18: 'th',
    19: 'th',
    20: 'th',
    21: 'st',
    22: 'nd',
    23: 'rd',
    24: 'th',
    25: 'th',
    26: 'th',
    27: 'th',
    28: 'th',
    29: 'th',
    30: 'th',
    31: 'st',
  }
}
