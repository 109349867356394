import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import Home from '@/views/Home.vue';
import Year from '@/views/Year.vue';
import YearDetail from '@/views/YearDetail.vue';
import Show from '@/views/Show.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/year/:year',
    name: 'Year',
    component: Year,
  },
  {
    path: '/year-detail/:year',
    name: 'YearDetail',
    component: YearDetail,
  },
  {
    path: '/year/:year/show/:slug',
    name: 'Show',
    component: Show,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
