
import { Options, Vue } from 'vue-class-component';
import {
  Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions,
} from '@headlessui/vue';
import { CheckIcon, SelectorIcon } from '@heroicons/vue/solid';

@Options({
  props: {
    // eslint-disable-next-line no-bitwise
    modelValue: { id: String, name: String },
    items: Array,
    label: String,
  },
  components: {
    Listbox,
    ListboxButton,
    ListboxLabel,
    ListboxOption,
    ListboxOptions,
    CheckIcon,
    SelectorIcon,
  },
})
export default class SelectMenu extends Vue {
  modelValue: { id: string, name: string } | null | undefined = null;

  items!: { id: string, name: string }[];

  get selectedName(): string {
    return this.modelValue?.name ?? '';
  }

  get selectedValue(): { id:string, name: string} | null | undefined {
    return this.modelValue;
  }

  set selectedValue(val: { id: string, name: string} | null | undefined) {
    this.$emit('update:modelValue', val);
  }
}
